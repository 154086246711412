<template>
  <div class="inspect xi">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>检验检测</h3>
        <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>检验检测</a></p>
      </div>
    </div>
    <div class="content-wrap">
      <div class="content-top">
        <ul class="diagnose-tab">
          <li>
            <router-link to="/diagnose/Diagnose">症状诊断</router-link>
          </li>
          <li class="active">
            <router-link to="/diagnose/Inspection">检验检测</router-link>
          </li>
          <li>
            <router-link to="/diagnose/signsCheck">体征检查</router-link>
          </li>
        </ul>
      </div>
      <div class="content-box clearfix">
        <div class="content-left">
          <h3>常用化验单</h3>
          <ul v-if="oftenreportList">
            <li v-for="(oftenreportItem, index) in oftenreportList" :key="index" @click="getInspectDetail(oftenreportItem.reportId), oftenreport(oftenreportItem.reportName, index)" :class="oftenIndex == index ? 'active' : ''">
              <span></span>
              {{ oftenreportItem.reportName }}
            </li>
          </ul>
        </div>
        <div class="ny_container">
          <div class="zztabfel clearfix">
            <p>字典大类：</p>
            <div>
              <a :class="currentIndex == index ? 'active' : ' '" v-for="(inspectList, index) in inspectClassify" :key="index" @click="handle(index)">{{ inspectList.categoryName }}</a>
            </div>
          </div>
          <div class="zztabfel clearfix">
            <p>字典小类：</p>
            <div :class="currentIndex == index ? 'isShow' : 'hidden'" v-for="(inspectList, index) in inspectClassify" :key="index">
              <a :class="currentID2 == index ? 'active' : ' '" v-for="(inspectItem, index) in inspectList.childrens" :key="index" :index="inspectItem.inspectionCategoryId + ''" @click="handleChild(index), getInspectList(inspectItem.inspectionCategoryId)">{{ inspectItem.categoryName }}</a>
            </div>
          </div>
          <div class="zztabfel clearfix">
            <p>检验检测项目：</p>
            <div>
              <a :class="currentID3 == index ? 'active' : ' '" v-for="(reportItem, index) in inspectList" :key="index" @click="handleChildrens(index, reportItem.reportName), getInspectDetail(reportItem.reportId)">{{ reportItem.reportName }}</a>
            </div>
            <!--<div class="nodata" v-show="inspectList == null || Object.keys(inspectList).length == 0 || !inspectDetail">

                        </div>-->
          </div>

          <!-- <div class="ins-tit">
                        <span v-for="(reportItem,i) in inspectList" @click="">{{reportItem.reportName}}</span>
                    </div> -->
          <div>
            <div class="nodata" v-if="!inspectDetail || inspectDetail == ''">
              <img src="../../../src/assets/img/no-data.png" alt="" />
            </div>
            <div v-else class="ins-table">
              <div class="ins-tit">
                {{ reportTitle }}
                <span v-html=""></span>
              </div>
              <el-table :data="inspectDetail" stripe style="width: 100%" :row-class-name="tableRowClassName">
                <!--@row-click = "onRowClick"-->
                <el-table-column prop="chineseName" label="项目全称" width="250">
                  <template slot-scope="scope">
                    <p>{{ scope.row.chineseName }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="short" label="项目简称" width="180">
                  <template slot-scope="scope">
                    <p>{{ scope.row.short }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="详情">
                  <template slot-scope="scope">
                    <el-link @click="openDetail(scope.row, index)" v-for="(item, index) in scope.row.infos" :key="index" type="primary" style="margin-right: 10px">{{ item.title }}</el-link>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
    <el-drawer v-if="inspectDetail != null && Object.keys(inspectDetail).length != 0" :title="inspectDetail[RowIndex].chineseName" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <div>
        <div class="drawer-tab clearfix">
          <!--<el-button @click="cur=0" :class="{active:cur==0}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '标本采集')">标本采集</el-button>
                    <el-button @click="cur=1" :class="{active:cur==1}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '试验方法')">试验方法</el-button>
                    <el-button @click="cur=2" :class="{active:cur==2}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '参考区间')">参考区间</el-button>
                    <el-button @click="cur=3" :class="{active:cur==3}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '临床意义')">临床意义</el-button>
                    <el-button @click="cur=4" :class="{active:cur==4}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '适应症')">适应症</el-button>
                    <el-button @click="cur=5" :class="{active:cur==5}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '禁忌症')">禁忌症</el-button>
                    <el-button @click="cur=6" :class="{active:cur==6}" :disabled="!inspectDetail[RowIndex].infos.some(i=>i.title === '评价')">评价</el-button>-->
          <el-button @click="cur = index" :class="{ active: cur == index }" v-for="(item, index) in inspectDetail[RowIndex].infos" :key="index">{{ item.title }}</el-button>
        </div>
        <el-scrollbar class="left-nav-wrap" style="height: 690px">
          <!-- 具体要包裹的内容 -->
          <div class="drawer-content">
            <div data-size="14" v-html="InfoRelation(inspectDetail[RowIndex].infos[cur])"></div>
          </div>
          <!--<div class="drawer-content"
                         v-show="cur==0"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '标本采集') == undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '标本采集').info"></div>
                    <div class="drawer-content"
                         v-show="cur==1"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '试验方法')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '试验方法').info"></div>
                    <div class="drawer-content"
                         v-show="cur==2"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '参考区间')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '参考区间').info"></div>
                    <div class="drawer-content"
                         v-show="cur==3"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '临床意义')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '临床意义').info"></div>
                    <div class="drawer-content"
                         v-show="cur==4"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '适应症')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '适应症').info"></div>
                    <div class="drawer-content"
                         v-show="cur==5"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '禁忌症')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '禁忌症').info"></div>
                    <div class="drawer-content"
                         v-show="cur==6"
                         v-html="inspectDetail[RowIndex].infos.find(i=>i.title === '评价')== undefined ? ''
                         : inspectDetail[RowIndex].infos.find(i=>i.title === '评价').info"></div>-->
        </el-scrollbar>
      </div>
    </el-drawer>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import { Inspection } from "../../components/Disease/Inspection";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
import { toDisease } from "../../utils/relation";
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
// import rtBar from "../../components/right";
var inspectionCategoryId = 0;
export default {
  name: "Inspection",
  components: {
    hdCom,
    ftCom,
    // rtBar
  },
  data() {
    var inspection = new Inspection(this.TokenClient, this.Services.Disease);
    var diseaseUtility = new DiseaseUtility(this.TokenClient, this.Services);
    return {
      drawer: false,
      direction: "rtl",
      inspectClassify: [],
      inspectList: [],
      inspectDetail: [],
      currentIndex: 0,
      currentID2: "",
      currentID3: "",
      cur: 0, //默认选中第一个tab
      rloading: [], //loading遮罩
      inspectionDomain: inspection,
      diseaseUtilityDomain: diseaseUtility,
      RowIndex: 0,
      reportTitle: "",
      oftenIndex: null,
      oftenreportList: [],
      currentlyAvailable: false,
    };
  },
  methods: {
    handleClose(done) {
      this.drawer = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    /*onRowClick (row, event, column) {
            //点击获取索引
            this.drawer = true;
            this.RowIndex = row.index;
        },*/
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    openDetail(row, i) {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.drawer = true;
      this.RowIndex = row.index;
      this.cur = i;
    },
    //分类获取
    getInspection: function () {
      var _this = this;
      _this.inspectionDomain.Inspections(
        function (data) {
          _this.inspectClassify = data.data;
          if (_this.inspectClassify[0] && _this.inspectClassify[0].childrens[0]) {
            inspectionCategoryId = _this.inspectClassify[0].childrens[0].inspectionCategoryId;
            _this.getInspectList(inspectionCategoryId);
          }
        },
        function (error) {
          console.log("检查检验字典请求失败!请刷新重试。", error);
        }
      );
    },
    //字典列表获取
    getInspectList: function (incataId) {
      if (incataId !== 0) {
        var _this = this;
        _this.rloading = this.openLoading();
        _this.cur == 0;
        let params = `/${incataId}`;
        _this.inspectionDomain.ReportAll(
          params,
          function (data) {
            _this.inspectList = data.data;
            inspectionCategoryId = incataId;
            if (_this.inspectList[0]) {
              _this.inspectList == [];
              _this.getInspectDetail(_this.inspectList[0].reportId);
            }
          },
          function (error) {
            console.log("检查检验字典分类请求失败!请刷新重试。", error);
            _this.inspectList = [];
            _this.inspectDetail = [];
            console.log(_this.inspectList);
          }
        );
        _this.rloading.close();
      }
    },
    //字典详情获取
    getInspectDetail: function (reportId) {
      var _this = this;
      let params = `${reportId}`;
      _this.inspectionDomain.ReportDetail(
        params,
        function (data) {
          _this.inspectDetail = data.data;
        },
        function (error) {
          console.log("检查检验字典详情请求失败!请刷新重试。", error);
        }
      );
    },
    //常见化验单列表获取
    getOftenReport() {
      var _this = this;
      _this.inspectionDomain.OftenReport(
        function (data) {
          _this.oftenreportList = data.data;
        },
        function (error) {
          console.log("常见化验单列表获取!请刷新重试。", error);
        }
      );
    },
    oftenreport(name, index) {
      var _this = this;
      _this.reportTitle = name;
      _this.oftenIndex = index;
      _this.currentID3 = null;
    },
    //内容关联
    InfoRelation: function (item) {
      this.$bus.$emit("fontSize");
      return replaceContent(item.info);
    },
    //跳转疾病详情
    getPageInfo(name, type, hashKey) {
      if (hashKey == "Search") {
        toSearchList(checkNameAndPath(type).typeName, name);
      } else {
        toDetailInfo(checkNameAndPath(type).path, hashKey, type);
      }
    },
    //跳转疾病详情
    toDiseaseDetail(id) {
      toDisease(id, 0, "");
    },
    handle: function (index) {
      this.currentIndex = index;
    },
    handleChild: function (index) {
      this.currentID2 = index;
      this.oftenIndex = null;
    },
    handleChildrens: function (index, reportTitle) {
      this.currentID3 = index;
      this.oftenIndex = null;
      this.reportTitle = reportTitle;
    },
  },
  mounted() {
    this.getInspection();
    this.getOftenReport();
  },
  created() {
    let _this = this;
    window.toDiseaseDetail = _this.toDiseaseDetail;
    window.getPageInfo = _this.getPageInfo;
  },
};
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

.banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner.png") center no-repeat;
}

.banner-text {
  width: 1200px;
  margin: 0 auto;
}

.banner-text h3 {
  padding-top: 25px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 18px;
  color: #ffffff;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}

.content-wrap {
  background: #fcfcfc;
  padding-bottom: 30px;
}

.content-top {
  height: 64px;
  background: #ffffff;
  box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
}

.diagnose-tab {
  width: 1200px;
  margin: 0 auto;
}

.diagnose-tab li,
.diagnose-tab li a {
  margin-right: 20px;
  display: inline-block;
  line-height: 63px;
  color: #808080;
  font-size: 16px;
}

.diagnose-tab li.active a {
  color: #1fb7a6;
  border-bottom: 2px solid #1fb7a6;
}

.isShow {
  display: block;
}

.hidden {
  display: none;
}

.content-box {
  width: 1200px;
  margin: 30px auto;
}

.content-left {
  float: left;
  width: 260px;
  padding: 30px 20px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
  min-height: 520px;
}

.content-left h3 {
  padding-left: 20px;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.content-left ul li {
  display: inline-block;
  position: relative;
  margin: 0 8px 8px 0;
  padding: 7px 12px;
  font-size: 15px;
  color: #666666;
  cursor: pointer;
  border: 1px solid #dddddd;
}

/*.content-left ul li span {
        position: absolute;
        left: 0;
        top: calc(50% - 4px);
        display: inline-block;
        width: 20px;
        height: 8px;
        margin-right: 20px;
    }
    .content-left ul li.active span {
        background: #03b29e;
    }*/
.content-left ul li.active,
.content-left ul li:hover {
  color: #1fb7a6;
  border: 1px solid #1fb7a6;
}

.ny_container {
  float: right;
  width: 920px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px 20px 30px;
  box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
}

.ny_container .zztabfel {
  padding: 5px 0 10px;
}

.ny_container .zztabfel > p {
  font-size: 18px !important;
  line-height: 3;
  font-weight: normal;
}

.ny_container .zztabfel:last-child {
  border-bottom: none;
}

.zztabfel a {
  margin: 0 8px 8px 0;
  float: left;
  padding: 7px 12px;
  display: inline-block;
  border: 1px #ddd solid;
  background: #fff;
  font-size: 15px;
  color: #666666;
  cursor: pointer;
}

.zztabfel a:hover,
.zztabfel a.active {
  color: #1fb7a6;
  border: 1px #1fb7a6 solid;
}

.ins-tit {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  background: #f2f2f2;
  color: #666666;
}

.ins-tit span {
  margin-right: 30px;
  cursor: pointer;
}

.ins-table {
  margin-top: 20px;
}

.ins-table table {
  width: 100%;
  border-collapse: collapse;
}

.ins-table table thead {
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
}

.ins-table table thead th {
  text-align: left;
  font-weight: normal;
  color: #454545;
  line-height: 45px;
}

.ins-table table th,
.ins-table table td {
  padding: 0 15px;
}

.ins-table table tbody {
  border-bottom: 1px solid #999999;
}

.ins-table table tbody tr:hover td {
  color: #1fb7a6;
  cursor: pointer;
}

.ins-table table tbody td {
  color: #666666;
  line-height: 42px;
}

/*drawer*/
.drawer-tab {
  border-bottom: 1px solid #999999;
  padding: 20px;
}

.drawer-tab li {
  margin: 0 8px 8px 0;
  float: left;
  padding: 7px 12px;
  display: inline-block;
  border: 1px #ddd solid;
  background: #fff;
  font-size: 15px;
  color: #666666;
  cursor: pointer;
}

.drawer-tab .disabled {
  cursor: not-allowed !important;
  color: #a29e9e !important;
  background: none !important;
}

.drawer-tab .active {
  color: #1fb7a6;
  border: 1px #1fb7a6 solid;
}

.el-button {
  margin: 0 10px 10px 0;
  padding: 10px 15px;
  border-radius: 0px;
}

.drawer-content {
  padding: 20px;
  color: #666666;
  line-height: 1.5;
}

.drawer-content p img {
  width: 100%;
}
</style>
<style>
.drawer-content p a {
  color: #1fb7a6 !important;
}

.drawer-content p img {
  width: 100%;
}

.inspect .bar-list .yysc {
  display: none !important;
}

.inspect .el-drawer {
  width: 500px !important;
}

.inspect .el-drawer__header {
  color: #333;
  padding: 15px 20px;
  background-color: #e0e0e0;
  margin-bottom: 0;
}

.el-scrollbar__wrap {
  overflow: auto;
}

.has-gutter tr th.is-leaf {
  border-bottom: 3px double #ebeef5;
}
</style>
